const zh_CN = {
  site_name: 'Online FTP Management System',
  feedback: 'Feedback',
  feedback_type: 'Type',
  feedback_type_item1: 'Function optimization',
  feedback_type_item2: 'Other',
  feedback_content: 'Content',
  feedback_content_placeholder: 'Please fill in your specific suggestion/complaint, which should be longer than 10 words',
  feedback_content_valid: 'Please fill in your specific suggestion/complaint',
  feedback_realname: 'Real name',
  feedback_realname_placeholder: 'Please enter your real name correctly',
  feedback_phone: 'Phone',
  feedback_phone_placeholder: 'Please enter your mobile phone number correctly',
  feedback_email: 'Email',
  feedback_email_placeholder: 'Please enter your email address correctly',
  feedback_success_text: 'Feedback success',
  logout_success_text: 'Exit successful!',

  submit: 'Submit',
  server_name: 'The FTP Server',
  upload: 'Upload',
  download: 'Download',
  copy: 'Copy',
  remove: 'Mobile',
  remove_to: 'Move to the',
  decompress: 'Unpack the',
  create: 'New',
  confirm: 'determine',
  affirm: 'Confirm',
  cancel: 'Cancel',
  save: 'Save',
  create_folder: 'New folder',
  folder_name: 'Folder name',
  create_file: 'New file',
  file_name: 'File name',
  file_content: 'File content',
  rename: 'Rename',
  compress: 'Compress',
  compress_title: 'You will create a compressed file',
  compress_to: 'Compress:',
  browse: 'Browse',
  hint: 'Tip:',
  compress_desc_one: 'The compressed task is executed in the background after submission. Please wait patiently.',
  compress_desc_two: 'Compression time is related to the number and size of files and the uplink bandwidth of your FTP server.',
  compress_desc_three: 'If the large file compression, it is recommended that you go to the user center control panel to execute;',
  compress_done: 'Immediately the compression',
  delete_title: 'Delete',
  delete_notice: 'Delete can not be restored, important files please pay attention to backup!',
  placeholder: 'Please enter folder/filename',
  all_directory: 'All directories',
  th_name: 'Name',
  th_size: 'Size',
  th_update: 'Modify the time',
  copy_floder: 'Copy files/folders',
  storage_path: 'The store path',
  remove_folder: 'Move files/folders',
  check_all: 'Future generations',
  edit: 'The editor',
  edit_notice: 'Please take care to back up the original file to avoid unrecoverable after modification',
  back_to_top: 'Back up one level',
  root_directory: 'The root directory',
  no_permission_text: 'No view permission',
  search: 'Search',
  search_placeholder: 'Please enter folder/filename',
  no_data_text: 'Temporarily no data',
  cut_choose_text: 'Please select the clipping object!',
  cut_choose_valid_text: 'Clipping objects can only select a single file or folder',
  cut_choose_success_text: 'You successfully cut the object, please go to the destination file path paste',
  copy_choose_text: 'Please select the copy object!',
  copy_choose_valid_text: 'A copy object can only select a single file or folder',
  copy_choose_success_text: 'You successfully copied the object, please go to the destination file path paste',
  paste_choose_text: 'You have not successfully copied or cut the object',
  paste_remove_text: 'You are sure to move the file to that path',
  paste_copy_text: 'You are sure to copy the file to this path',

  remove_valid_test: 'Moved destination path cannot be the same as moved directory path!',
  remove_success_text: 'Move successful!',
  copy_success_text: 'Copy successful!',
  delete_success_text: 'Deleted successfully!',
  delete_fail_text: 'Deletion failed!',
  download_file_limit: 'The maximum download file size cannot exceed 100MB',
  download_file_text: 'Download successful!',
  submit_success_text: 'Submitted successfully, please refresh the list later!',
  create_folder_text: 'New folder successful!',
  create_folder_valid_text: 'Please select the directory where the new folder is located',
  create_folder_noroot_text: 'A new folder cannot be created in the root directory',
  create_file_text: 'New file created!',
  edit_success_text: 'Edit successful!',
  rename_success_text: 'Rename successful!',
  upload_valid_text: 'The maximum size of the uploaded file is 20M',
  upload_success_text: 'Upload successful!',
  folder: 'folder',
  file: 'File',
  view_file: 'Check the file',
  edit_file: 'Edit the file',


  loading_text: 'In the load......',
  file_valid_text: 'Please enter file name',
  folder_valid_text: 'Please enter the folder name',
  storage_valid_text: 'Please select the storage path!',

  preview_image_delete: 'Are you sure to delete this picture?',
  preview_detail_text: 'Photos for details',
  file_name_new: 'The file name',
  belong_to_folder: 'Belong to folder',
  time: 'time',

  set_download_storage: 'Set the download storage path',
  open: 'open',
  storeage_path: 'The store path:',
  uncompress_file: 'Unzip the files',
  uncompress_done: 'immediately Unzip',
  uncompress_to: 'unzip to',
  uncompress_password: 'Unzip password：',
  uncompress_desc_one: 'Background execution after unzip task submission, please wait patiently;',
  uncompress_desc_two: 'Decompression time is related to the number and size of files and the uplink bandwidth of your FTP server.',
  uncompress_desc_three: 'If the large file is unzipped, it is recommended that you go to the user center control panel for execution;',

}
export default zh_CN; 