const zh_CN = {
  site_name: '在线FTP管理系统',
  feedback: '意见反馈',
  feedback_type: '反馈类型',
  feedback_type_item1: '功能优化',
  feedback_type_item2: '其它',
  feedback_content: '反馈内容',
  feedback_content_placeholder: '请填写您的具体建议/投诉，长度需要大于10个字',
  feedback_content_valid: '请填写您的具体建议/投诉',
  feedback_realname: '真实姓名',
  feedback_realname_placeholder: '请正确输入您的真实姓名',
  feedback_phone: '手机号码',
  feedback_phone_placeholder: '请正确输入您的手机号码',
  feedback_email: '电子邮箱',
  feedback_email_placeholder: '请正确输入您的邮箱地址',
  feedback_success_text: '反馈成功',
  logout_success_text: '退出成功！',

  submit: '提交',
  server_name: 'FTP服务器',
  upload: '上传',
  download: '下载',
  copy: '复制',
  remove: '移动',
  remove_to: '移动到',
  decompress: '解压',
  create: '新建',
  confirm: '确定',
  affirm: '确认',
  cancel: '取消',
  save: '保存',
  create_folder: '新建文件夹',
  folder_name: '文件夹名称',
  create_file: '新建文件',
  file_name: '文件名称',
  file_content: '文件内容',
  rename: '重命名',
  compress: '压缩',
  compress_title: '您将创建一个压缩文件',
  compress_to: '压缩到：',
  browse: '浏览',
  hint: '提示：',
  compress_desc_one: '压缩任务提交后后台执行，请耐心等待；',
  compress_desc_two: '压缩时间与文件数量、大小及您的FTP服务器上行带宽有关，如果耗时较长可能失败；',
  compress_desc_three: '如果执大文件压缩，建议您前往用户中心控制面板执行；',
  compress_done: '立即压缩',
  delete_title: '删除',
  delete_notice: '删除后不可恢复，重要文件请注意备份!',
  placeholder: '请输入文件夹/文件名',
  all_directory: '全部目录',
  th_name: '名称',
  th_size: '大小',
  th_update: '修改时间',
  copy_floder: '复制文件/文件夹',
  storage_path: '存储路径',
  remove_folder: '移动文件/文件夹',
  check_all: '全选',
  edit: '编辑',
  edit_notice: '请注意备份原文件，避免修改后无法恢复',
  back_to_top: '返回上一级',
  root_directory: '根目录',
  no_permission_text: '暂无查看权限',
  search: '搜索',
  search_placeholder: '请输入文件夹/文件名',
  no_data_text: '暂无数据',
  cut_choose_text: '请选中剪切对象！',
  cut_choose_valid_text: '剪切对象只能选择单个文件或者文件夹',
  cut_choose_success_text: '您成功了剪切了对象，请去目标文件路径粘贴',
  copy_choose_text: '请选中复制对象！',
  copy_choose_valid_text: '复制对象只能选择单个文件或者文件夹',
  copy_choose_success_text: '您成功了复制了对象，请去目标文件路径粘贴',
  paste_choose_text: '您还没有成功复制或剪切对象',
  paste_remove_text: '你确定将文件移动到该路径下',
  paste_copy_text: '你确定复制文件到该路径下',

  remove_valid_test: '移动的目标路径不能与被移动目录路径一致！',
  remove_success_text: '移动成功！',
  copy_success_text: '复制成功！',
  delete_success_text: '删除成功！',
  delete_fail_text: '删除失败！',
  download_file_limit: '下载文件大小最大不能超过100MB',
  download_file_text: '下载成功！',
  submit_success_text: '提交成功，请稍后刷新列表查看!',
  create_folder_text: '新建文件夹成功！',
  create_folder_valid_text: '请选择新建文件夹所在的目录',
  create_folder_noroot_text: '此地新建文件夹不可在根目录',
  create_file_text: '新建文件成功！',
  edit_success_text: '编辑成功！',
  rename_success_text: '重命名成功！',
  upload_valid_text: '上传文件最大为20M',
  upload_success_text: '上传成功！',
  folder: '文件夹',
  file: '文件',
  view_file: '查看文件',
  edit_file: '编辑文件',


  loading_text: '加载中......',
  file_valid_text: '请输入文件名称',
  folder_valid_text: '请输入文件夹名称',
  storage_valid_text: '请选择存储路径！',

  preview_image_delete: '确定删除这张图片吗?',
  preview_detail_text: '照片详情',
  file_name_new: '文件名',
  belong_to_folder: '所属文件夹',
  time: '时间',

  set_download_storage: '设置下载存储路径',
  open: '打开',
  storeage_path: '存储路径:',
  uncompress_file: '解压文件',
  uncompress_done: '立即解压',
  uncompress_to: '解压到',
  uncompress_password: '解压密码：',
  uncompress_desc_one: '解压任务提交后后台执行，请耐心等待；',
  uncompress_desc_two: '解压时间与文件数量、大小及您的FTP服务器上行带宽有关，如果耗时较长可能失败；',
  uncompress_desc_three: '如果执大文件解压，建议您前往用户中心控制面板执行；',

}
export default zh_CN; 